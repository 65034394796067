<template>
  <div>
    <Hero />
    <hr />
    <div class="notFound col-md-10 mb-4 mx-auto d-block">
    <p class="centered">That route does not exist on this web site.</p> 
    </div>
    </div>
</template>

<script>
    import Hero from "../components/Hero";

    export default {
        components: {
            Hero,
    },
        name: 'NotFound'
    }
</script>

<style scoped>
div.notFound {
    background-color: lightgray;
    padding: 12px;
    margin: 0 auto;
}
p.centered {
     text-align: center;
}
</style>